import React from 'react';
import './Editor.css';

import { connect } from 'react-redux';

import { ActionTypes, IMap, EmojiType, IFrameSettings } from './ReducerTypes';
import { changeBasemap, changeEmoji, setMapContent, updateFrameSettings } from './ReducerActions';

import { State } from './ReducerTypes';

import Twemoji from 'react-twemoji';

interface IProps {
}

interface StateProps {

  emoji: EmojiType;
  mapContent: string;
  frameSettings: IFrameSettings;
}

interface IState {
  
  basemapOpened: boolean;
  frameSettingsOpened: boolean;
  emojiSettingsOpened: boolean;
  key: number;
}

interface DispatchProps {
    changeBasemap: (basemap: IMap) => void;
    changeEmoji: (emoji: EmojiType) => void;
    setMapContent: (text: string) => void;
    updateFrameSettings: (frameSettings: IFrameSettings) => void;
}

type Props = StateProps & DispatchProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
      emoji: state.emoji,
      mapContent: state.mapContent,
      frameSettings: state.frameSettings,
    };
}

const mapDispatchToProps = {
    
    changeBasemap,
    changeEmoji,
    setMapContent,
    updateFrameSettings
};

class Editor extends React.Component<Props, IState> {

  private basemaps : IMap[] = [
    { name:"Tweetmymap White", style:"./tweetmymap_white_style.json" },
    { name:"Tweetmymap Black", style:"./tweetmymap_black_style.json" },
    { name:"OpenStreetMap", style:"./openstreetmap_style.json" },
    { name:"Stamen Toner", style:"./stamen_toner_style.json" },
    { name:"Stamen Terrain", style:"./stamen_terrain_style.json" },
    { name:"Stamen Watercolor", style:"./stamen_watercolor_style.json" },
    { name:"Esri Satellite", style:"./esri_satellite_style.json" },
    { name:"Esri Topo", style:"./esri_topo_style.json" }
  ];

  private frameRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {

    super(props);

    this.lockMap = this.lockMap.bind(this);
    this.openBasemap = this.openBasemap.bind(this);
    this.closeBasemap = this.closeBasemap.bind(this);
    this.setBasemap = this.setBasemap.bind(this);
    this.frameSettings = this.frameSettings.bind(this);
    this.emojiSettings = this.emojiSettings.bind(this);
    this.closeEmojiSettings = this.closeEmojiSettings.bind(this);
    this.closeFrameSettings = this.closeFrameSettings.bind(this);
    this.changeFrameOpacity = this.changeFrameOpacity.bind(this);
    this.changeFrameColor = this.changeFrameColor.bind(this);
    this.changeFrameWidth = this.changeFrameWidth.bind(this);
    this.changeFrameHeight = this.changeFrameHeight.bind(this);
    this.changeFrameOutlineWidth = this.changeFrameOutlineWidth.bind(this);
    this.changeFrameOutlineColor = this.changeFrameOutlineColor.bind(this);
    this.changeFrameFontSize = this.changeFrameFontSize.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeEmoji = this.changeEmoji.bind(this);

    this.frameRef = React.createRef<HTMLInputElement>();

    this.state = {
      basemapOpened: false,
      frameSettingsOpened: false,
      emojiSettingsOpened: false,
      key: 0
    };
  }

  componentDidMount() {

    this.updateFrameContent();
  }

  lockMap(event: any) {


  }

  openBasemap(event: any) {

    this.setState({ basemapOpened: true, frameSettingsOpened: false, emojiSettingsOpened: false });
  }

  closeBasemap(event: any) {

    this.setState({ basemapOpened: false });
  }

  setBasemap(index: number) {

    console.log('setBasemap:'+index);
    
    this.props.changeBasemap(this.basemaps[index]);
  }

  checkFrameSizeChange() {

    if (this.frameRef && this.frameRef.current) {

      let frame: any;

      if ("_reactInternals" in this.frameRef.current) {

        const obj: any = this.frameRef.current;

        frame = obj.rootRef!.current!;
      }
      else {

        frame = this.frameRef.current;
      }

      if (frame) {

        let updated = false;

        const regex = new RegExp("([0-9]*).*");

        let res = frame.style.width.match(regex);

        if (res) {

          const width = parseInt(res[1]);

          if (this.props.frameSettings.width != width) {

            this.props.frameSettings.width = width;
            updated = true;
          }
        }

        res = frame.style.height.match(regex);

        if (res) {

          const height = parseInt(res[1]);

          if (this.props.frameSettings.height != height) {

            this.props.frameSettings.height = height;
            updated = true;
          }
        }

        if (updated) {

          this.props.updateFrameSettings(this.props.frameSettings);
        }
      }
    }
  }

  frameSettings(event: any) {

    this.setState({ basemapOpened: false, frameSettingsOpened: true, emojiSettingsOpened: false });

    this.checkFrameSizeChange();
  }

  emojiSettings(event: any) {

    this.setState({ basemapOpened: false, frameSettingsOpened: false, emojiSettingsOpened: true });
  }

  closeEmojiSettings(event: any) {

    this.setState({ emojiSettingsOpened: false });
  }

  closeFrameSettings(event: any) {

    this.setState({ frameSettingsOpened: false });
  }

  changeFrameOpacity(event: any) {

    this.props.frameSettings.opacity = event.target.value*0.01;

    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameColor(event: any) {

    this.props.frameSettings.color = event.target.value;

    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameWidth(event: any) {

    let width = parseInt(event.target.value);

    if (!(width >= 0)) {

      width = 0;
    }

    this.props.frameSettings.width = width;

    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameHeight(event: any) {

    let height = parseInt(event.target.value);

    if (!(height >= 0)) {

      height = 0;
    }

    this.props.frameSettings.height = height;

    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameOutlineWidth(event: any) {

    let width = parseInt(event.target.value);

    if (!(width >= 0)) {

      width = 0;
    }

    this.props.frameSettings.outlineWidth = width;

    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameOutlineColor(event: any) {

    this.props.frameSettings.outlineColor = event.target.value;
    
    this.props.updateFrameSettings(this.props.frameSettings);
  }

  changeFrameFontSize(event: any) {

    let fontSize = parseInt(event.target.value);

    if (!(fontSize >= 0)) {

      fontSize = 0;
    }

    this.props.frameSettings.fontSize = fontSize;
    
    this.props.updateFrameSettings(this.props.frameSettings);
  }

  htmlToText(html: string) {

    const regex = new RegExp('<img[^>]*/([0-9a-fA-F]*)[.]svg">', 'g');

    const text = html.replaceAll(regex, function(match, capture) {

      return String.fromCodePoint(parseInt(capture,16));
    });

    return text;
  }

  onPaste(e: any) {

    e.preventDefault();

    const text = (e.originalEvent || e).clipboardData.getData('text/plain');

    if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
    } else {
        document.execCommand('paste', false, text);
    }

    this.setState({ key: this.state.key+1 });

    this.checkFrameSizeChange();
  }

  onChange(e: any) {

    const text = this.htmlToText(e.target.innerHTML);

    console.log(text);

    this.props.setMapContent(text);

    this.setState({ key: this.state.key+1 });

    this.checkFrameSizeChange();
  }

  updateFrameContent() {

    const _this = this;

    setTimeout(function() {

      if (_this.frameRef && _this.frameRef.current) {

        if ("_reactInternals" in _this.frameRef.current) {

          const obj: any = _this.frameRef.current;

          obj.rootRef!.current!.innerHTML = _this.props.mapContent;
        }
        else {
          _this.frameRef.current.innerHTML = _this.props.mapContent;
        } 

        _this.setState({ key: _this.state.key+1 });
      }

    },10);
  }

  changeEmoji(emoji: EmojiType) {

    this.checkFrameSizeChange();

    this.props.changeEmoji(emoji);

    this.updateFrameContent();
  }

  renderBasemap() {

    if (this.state.basemapOpened) {

      return (<div className="Basemap">

        <div>

          <ul className="SettingsList">

            { this.basemaps.map((b,index) => { return (<li className="SettingsListItem" onClick={e => this.setBasemap(index)}>{b.name}</li>)}) }

          </ul>

          <button className="SettingsClose" onClick={this.closeBasemap}>Close</button>

        </div>

        </div>
      );
    }
  }

  renderFrameSettings() {

    if (this.state.frameSettingsOpened) {

      return (<div className="FrameSettings">

        <div>

          <div className="FrameFeature">
            <div>Opacity</div><input type="range" min="0" max="100" value={this.props.frameSettings.opacity*100} onChange={this.changeFrameOpacity}/>
          </div>
          <div className="FrameFeature">
            <div>Colour</div>
            <input type="color" value={this.props.frameSettings.color} onChange={this.changeFrameColor} style={{padding:0,border:0}}/>
          </div>
          <div className="FrameFeature">
            <div>Width</div>
            <input type="text" value={this.props.frameSettings.width} onChange={this.changeFrameWidth}/>
          </div>
          <div className="FrameFeature">
            <div>Height</div>
            <input type="text" value={this.props.frameSettings.height} onChange={this.changeFrameHeight}/>
          </div>
          <div className="FrameFeature">
            <div>Outline width</div>
            <input type="text" value={this.props.frameSettings.outlineWidth} onChange={this.changeFrameOutlineWidth}/>
          </div>

          <div className="FrameFeature">
            <div>Outline color</div>
            <input type="color" value={this.props.frameSettings.outlineColor} onChange={this.changeFrameOutlineColor} style={{padding:0,border:0}}/>
          </div>

          <div className="FrameFeature">
            <div>Font size</div>
            <input type="text" value={this.props.frameSettings.fontSize} onChange={this.changeFrameFontSize}/>
          </div>

          <button className="FrameSettingsClose" onClick={this.closeFrameSettings}>Close</button>

        </div>

        </div>
      );
    }
  }

  renderEmojiSettings() {

    if (this.state.emojiSettingsOpened) {

      return (<div className="EmojiSettings">

          <div>
            <ul className="SettingsList">
              <li className="SettingsListItem" onClick={e => this.changeEmoji(EmojiType.TwitterEmoji)}>Twitter&nbsp;emoji</li>
              <li className="SettingsListItem" onClick={e => this.changeEmoji(EmojiType.BrowserEmoji)}>Browser&nbsp;emoji</li>
            </ul>

            <button className="SettingsClose" onClick={this.closeEmojiSettings}>Close</button>

          </div>

        </div>
      );
    }
  }

  getR(color: string) {

    return parseInt(color[1]+color[2], 16);
  }

  getG(color: string) {

    return parseInt(color[3]+color[4], 16);
  }

  getB(color: string) {

    return parseInt(color[5]+color[6], 16);
  }

  renderInput() {

    if (this.props.emoji == EmojiType.TwitterEmoji) {

      return (<Twemoji id="frame" ref={this.frameRef} options={{ folder: 'svg', ext: '.svg' }} style={{backgroundColor: 'rgba('+this.getR(this.props.frameSettings.color)+','+this.getG(this.props.frameSettings.color)+','+this.getB(this.props.frameSettings.color)+','+this.props.frameSettings.opacity+')', borderColor: this.props.frameSettings.outlineColor, width: this.props.frameSettings.width, height: this.props.frameSettings.height, fontSize: this.props.frameSettings.fontSize+"px", lineHeight: Math.floor(this.props.frameSettings.fontSize*1.263)+"px", borderWidth: this.props.frameSettings.outlineWidth+"px" }} {...{'contenteditable':'true','data-key':this.state.key}} onPaste={this.onPaste} onChange={this.onChange} onInput={this.onChange}></Twemoji>);
    }
    else if (this.props.emoji == EmojiType.BrowserEmoji) {

      return (<div id="frame" ref={this.frameRef} style={{backgroundColor: 'rgba('+this.getR(this.props.frameSettings.color)+','+this.getG(this.props.frameSettings.color)+','+this.getB(this.props.frameSettings.color)+','+this.props.frameSettings.opacity+')', borderColor: this.props.frameSettings.outlineColor, width: this.props.frameSettings.width, height: this.props.frameSettings.height, fontSize: this.props.frameSettings.fontSize+"px", lineHeight: Math.floor(this.props.frameSettings.fontSize*1.263)+"px", borderWidth: this.props.frameSettings.outlineWidth+"px" }} {...{'contenteditable':'true','data-key':this.state.key}} onPaste={this.onPaste} onChange={this.onChange} onInput={this.onChange}></div>);
    }
  }

  render() {

    return (
      <div className="Editor">

        { this.renderInput() }

        <div className="Menu">
          <span className="MenuItem" onClick={this.openBasemap}>&#x1F5FA;</span>
          <span className="MenuItem" onClick={this.frameSettings}>&#x1F5BC;</span>
          <span className="MenuItem" onClick={this.emojiSettings}>&#x1F426;</span>
        </div>

        <div className="Counter">
          <span>{this.props.mapContent.length}</span>
          <span>&nbsp;word{(this.props.mapContent.length > 1)?"s":""}</span>
          <span style={{color: "red"}}>{(this.props.mapContent.length > 280) ? "\u00A0" + "("+ (280-this.props.mapContent.length) + ")" : "" }</span>
        </div>

        { this.renderBasemap() }
        { this.renderFrameSettings() }
        { this.renderEmojiSettings() }
        
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Editor);
