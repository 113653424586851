import React from 'react';
import './App.css';

import Header from './Header';
import Table from './Table';
import Map from './Map';

import { connect } from 'react-redux';

import { ActionTypes } from './ReducerTypes';
import { hideChangelog, closeContact } from './ReducerActions';

import { State } from './ReducerTypes';

import ReactMarkdown from 'react-markdown';

interface IProps {
}

interface StateProps {

  changelog_displayed: boolean;
  contact_opened: boolean;
}

interface IState {

  changelog: string;
  messageName: string;
  messageEmail: string;
  messageTitle: string;
  messageText: string;
}

interface DispatchProps {
    hideChangelog: () => void;
    closeContact: () => void;
}

type Props = StateProps & DispatchProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
      changelog_displayed: state.changelog_displayed,
      contact_opened: state.contact_opened
    };
}

const mapDispatchToProps = {

    hideChangelog,
    closeContact
};

class App extends React.Component<Props, IState> {

  constructor(props: Props) {

    super(props);

    this.hideChangelog = this.hideChangelog.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeMessageName = this.changeMessageName.bind(this);
    this.changeMessageEmail = this.changeMessageEmail.bind(this);
    this.changeMessageTitle = this.changeMessageTitle.bind(this);
    this.changeMessageText = this.changeMessageText.bind(this);
    this.closeContact = this.closeContact.bind(this);

    this.state = {
      changelog: '',
      messageName: '',
      messageEmail: '',
      messageTitle: '',
      messageText: ''
    };
  }

  componentDidMount() {

    fetch(`CHANGELOG.md`)
      .then(res => res.text())
      .then(text => this.setState({changelog: text}));
  }

  hideChangelog() {

    this.props.hideChangelog();
  }

  renderChangelog() {

    if (this.props.changelog_displayed) {

      return (<div className="Changelog"><div><button onClick={this.hideChangelog}>Close</button><ReactMarkdown>{this.state.changelog}</ReactMarkdown></div></div>);
    }
  }

  closeContact() {

    this.props.closeContact();
  }

  changeMessageName(event: any) {

    this.setState({ messageName: event.target.value });
  }

  changeMessageEmail(event: any) {

    this.setState({ messageEmail: event.target.value });
  }

  changeMessageTitle(event: any) {

    this.setState({ messageTitle: event.target.value });
  }

  changeMessageText(event: any) {

    this.setState({ messageText: event.target.value });
  }

  sendMessage() {

    if (!this.state.messageName || (this.state.messageName.length == 0))
      return;
    if (!this.state.messageEmail || (this.state.messageEmail.length == 0))
      return;
    if (!this.state.messageTitle || (this.state.messageTitle.length == 0))
      return;
    if (!this.state.messageText || (this.state.messageText.length == 0))
      return;

    fetch('send_message.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.messageName,
        email: this.state.messageEmail,
        title: this.state.messageTitle,
        text: this.state.messageText})
    })
      .then(res => res.json())
      .then(json => {this.closeContact()});
  }

  renderContact() {

    if (this.props.contact_opened) {

      return (<div className="Contact"><div className="ContactForm"><input type="text" placeholder="name" onChange={this.changeMessageName}/><input type="text" placeholder="email" onChange={this.changeMessageEmail}/><input type="text" placeholder="title" onChange={this.changeMessageTitle}/><textarea placeholder="enter your message" rows={7} onChange={this.changeMessageText}></textarea><div><button onClick={this.closeContact}>Cancel</button><button onClick={this.sendMessage}>Send</button></div></div></div>);
    }
  }

  render() {

    return (
      <div className="App">
        <Header />
        <Table />
        <Map />
        { this.renderChangelog() }
        { this.renderContact() }
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
