import React from 'react';
import './Table.css';

import Twemoji from 'react-twemoji';

import { connect } from 'react-redux';

import { ActionTypes } from './ReducerTypes';

import { State, EmojiType } from './ReducerTypes';

interface IProps {
}

interface StateProps {

  emoji: EmojiType;
}

type Props = StateProps & IProps;

interface Block {

  start: number;
  end: number;
  name: string;
  visible: boolean;
}

interface Symbol {

  code: number;
  name: string;
}

interface IState {

  blocks: Block[];
  symbols: Symbol[];
  selectedBlock: string;
  foundSymbols: Symbol[];
}

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
      emoji: state.emoji
    };
}

class Table extends React.Component<Props, IState> {

  constructor(props: Props) {

    super(props);

    this.state = {
      blocks: [],
      symbols: [],
      selectedBlock: "",
      foundSymbols: [],
    };

    this.copy = this.copy.bind(this);
    this.openBlock = this.openBlock.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {

    fetch(`Blocks.txt`)
      .then(res => res.text())
      .then(text => this.parseBlocks(text));

    fetch(`UnicodeData.txt`)
      .then(res => res.text())
      .then(text => this.parseUnicodeData(text));
  }

  parseBlocks(text: string) {

    const regex = new RegExp("([0-9A-F]*)..([0-9A-F]*);[ ]*(.*)");

    const array = text.split(/\r?\n/);

    let blocks = [];

    for (let line of array) {

      if (line[0] != '#') {

        const res = line.match(regex);

        if (res) {

          const start = parseInt(res[1], 16);
          const end = parseInt(res[2], 16);

          blocks.push({start:start, end:end, name: res[3], visible: true});
        }
      }
    }

    this.setState({blocks: blocks});
  }

  parseUnicodeData(text: string) {

    const regex = new RegExp("([0-9A-F]*);([^;]*);.*");

    const array = text.split(/\r?\n/);

    let symbols: Symbol[];

    symbols = [];

    for (let line of array) {

      const res = line.match(regex);

      if (res) {

        const code = parseInt(res[1], 16);

        symbols[code] = {code:code, name: res[2]};
      }
    }

    this.setState({symbols: symbols});
  }

  copy(e: any) {

    /*if (document.body.createTextRange) {

      let range = document.body.createTextRange();

      range.moveToElementText(e.target);
      range.select();

      document.execCommand("Copy");
    }
    else  if (window.getSelection)*/ {

      // other browsers

      console.log(e.target);

      let selection: any | null;

      selection = window.getSelection();

      let range = document.createRange();

      if (e.target.nodeName.toLowerCase() == "div") {
        console.log("No emoji");
        console.log(e.target);
        range.selectNodeContents(e.target);
      }
      else {
        console.log("Emoji");
        console.log(e.target.parentNode.parentNode.childNodes[1]);
        range.selectNodeContents(e.target.parentNode.parentNode.childNodes[1]);
      }

      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");

      selection.removeAllRanges();
    }

    e.stopPropagation();
  }

  openBlock(block: Block) {

    this.setState({selectedBlock: block.name});
  }

  closeBlock(block: Block) {

    this.setState({selectedBlock: ""});
  }

  onSearch(e: any) {

    if ( !e.target.value || (e.target.value.length < 3)) {

      this.setState({foundSymbols: [],blocks: this.state.blocks.filter(b => b.start >= 0)});
      this.state.blocks.forEach(b => {b.visible = true});

      return;
    }

    const s = e.target.value.toLowerCase();

    let symbols: Symbol[];

    symbols = [];

    let i = 0;

    for (let c in this.state.symbols) {

      if (this.state.symbols[c].name.toLowerCase().indexOf(s) >= 0) {

        //console.log(this.state.symbols[c].name);
        symbols.push(this.state.symbols[c]);

        i += 1;
      }
    }

    this.state.blocks.forEach(b => {b.visible = false});

    this.state.blocks.unshift({start:-1, end:-1, name: i+" characters found", visible: true});

    this.setState({foundSymbols: symbols, blocks: this.state.blocks});
  }

  renderBlock(block: Block) {

    if (!block.visible)
      return;

    if (block.start < 0) {

      if (this.props.emoji == EmojiType.TwitterEmoji) {

        return (<li className="BlockSelected" onClick={(e:any) => this.closeBlock(block)}><div>{block.name}</div><div className="Symbols">{this.state.foundSymbols.map(c => ( <div><Twemoji options={{ folder: 'svg', ext: '.svg', className: '' }} title={c.code.toString(16)+": "+c.name} onClick={this.copy}>{String.fromCodePoint(c.code)}</Twemoji><span className="emojiHidden">{String.fromCodePoint(c.code)}</span></div>))}</div></li>);
      }
      else if (this.props.emoji == EmojiType.BrowserEmoji) {

        return (<li className="BlockSelected" onClick={(e:any) => this.closeBlock(block)}><div>{block.name}</div><div className="Symbols">{this.state.foundSymbols.map(c => (<div className="Char" title={c.code.toString(16)+": "+c.name} onClick={this.copy}>{String.fromCodePoint(c.code)}</div>))}</div></li>);
      }

    }
    else if (block.name == this.state.selectedBlock) {

      let symbols = [];

      for (let i = block.start;i <= block.end; i+= 1) {

        if (i in this.state.symbols) {

          symbols.push(this.state.symbols[i]);
        }
      }

      if (this.props.emoji == EmojiType.TwitterEmoji) {

        return (<li className="BlockSelected" onClick={(e:any) => this.closeBlock(block)} title={block.start.toString(16)+"..."+block.end.toString(16)}><div>{block.name}</div><div className="Symbols">{symbols.map(c => (<div><Twemoji options={{ folder: 'svg', ext: '.svg', className: '' }} title={c.code.toString(16)+": "+c.name} onClick={this.copy}>{String.fromCodePoint(c.code)}</Twemoji><span className="emojiHidden">{String.fromCodePoint(c.code)}</span></div>))}</div></li>);

      }
      else if (this.props.emoji == EmojiType.BrowserEmoji) {

        return (<li className="BlockSelected" onClick={e => this.closeBlock(block)} title={block.start.toString(16)+"..."+block.end.toString(16)}><div>{block.name}</div><div className="Symbols">{symbols.map(c => (<div className="Char" title={c.code.toString(16)+": "+c.name} onClick={this.copy}>{String.fromCodePoint(c.code)}</div>))}</div></li>);
      }
    }
    else {

      return (<li className="Block" onClick={e => this.openBlock(block)} title={block.start.toString(16)+"..."+block.end.toString(16)}>{block.name}</li>);
    }
  }

  render() {

    return (

      <div className="Table">

        <input type="text" className="Search" placeholder="Search Unicode character" onChange={this.onSearch}></input>

        <ul>
          {this.state.blocks.map(b => this.renderBlock(b))}
        </ul>

      </div>
    );
  }
}

export default connect(mapStateToProps)(Table);

