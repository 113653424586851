import React from 'react';
import './Header.css';

import { connect } from 'react-redux';

import { ActionTypes } from './ReducerTypes';
import { showChangelog, openContact } from './ReducerActions';

import { State } from './ReducerTypes';


interface IProps {
}

interface StateProps {
}

interface IState {
}

interface DispatchProps {
    showChangelog: () => void;
    openContact: () => void;
}

type Props = DispatchProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return { };
}

const mapDispatchToProps = {

  showChangelog,
  openContact,
};

class Header extends React.Component<Props, IState> {

  constructor(props: Props) {

    super(props);

    this.showChangelog = this.showChangelog.bind(this);
    this.openContact = this.openContact.bind(this);
  }

  showChangelog(event: any) {

    this.props.showChangelog();
  }

  openContact(event: any) {

    this.props.openContact();
  }

  render() {

    return (
      <div className="Header">

        <div>
          <img className="logo" src="logo_tweetmymap_transparent.png" />
          <div className="title">
            <div className="tweetmymap">Tweet my map</div>
            <div className="version" onClick={this.showChangelog}>v0.5.0</div>
            <div className="by">by</div>
            <a className="meandmaps" href="https://www.meandmaps.com" target="_blank">me & maps</a>
          </div>
        </div>
        <div>
          <span className="HeaderItem" onClick={this.openContact}>Any suggestion ? &#x1F4E9;</span>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header);
