import React from 'react';

import mapboxgl from 'mapbox-gl';

import './mapbox-gl.css'
import './Map.css';

import Editor from './Editor';

import { connect } from 'react-redux';

import { State, IMap, IMapPosition } from './ReducerTypes';
import { updateMapPosition } from './ReducerActions';

interface IProps {
}

interface IState {
}

interface StateProps {

  basemap: IMap;
  mapPosition: IMapPosition;
}

interface DispatchProps {
    updateMapPosition: (mapPosition: IMapPosition) => void;
}

type Props = DispatchProps & StateProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
      basemap: state.basemap,
      mapPosition: state.mapPosition
    };
}

const mapDispatchToProps = {
    
    updateMapPosition
};

class Map extends React.Component<Props, IState> {

  private map: mapboxgl.Map | null = null;

  constructor(props: Props) {
    
    super(props);
  }

  componentDidMount() {

    this.map = new mapboxgl.Map({
            container: 'map',
            style: this.props.basemap.style,
            attributionControl: true,
            maxZoom: 18
        });

    this.map.addControl(new mapboxgl.AttributionControl({
        compact: false,
        customAttribution: '<a href="https://www.meandmaps.com" target="_blank">© me & maps</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
    }));

    var navigate = new mapboxgl.NavigationControl({

      showZoom: true,
      visualizePitch: true
    });

    this.map.addControl(navigate, 'bottom-right');

    const _this = this;

    setTimeout(function() {

      _this!.map!.setCenter([_this!.props.mapPosition.lng,_this!.props.mapPosition.lat]);
      _this!.map!.setZoom(_this!.props.mapPosition.zoom);
      _this!.map!.setBearing(_this!.props.mapPosition.bearing);
      _this!.map!.setPitch(_this!.props.mapPosition.pitch);

      _this.map!.on('moveend', function(e) {

        const center = e.target.getCenter();

        _this.props.updateMapPosition({

          lng: center.lng,
          lat: center.lat,
          zoom: e.target.getZoom(),
          bearing: e.target.getBearing(),
          pitch: e.target.getPitch()
        });
      });

    },500);

  }

  renderMap() {

    if (this.map) {

      this.map.setStyle(this.props.basemap.style);
    }

    return (<div id='map'></div>);
  }

  render() {

    return (
      <div className="Map">
        { this.renderMap() }
        <Editor />
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Map);
